<template>
    <div id="ItemContainer" class="margintop">
        <div id="TopbarContainer">
            <h1 id="Title" class="px-1">Notas de Atualização{{['md', 'lg', 'xl'].includes($vuetify.breakpoint.name) ? ' do Sistema' : ''}}</h1>
        </div>
        <div id="ItemDisplay">
            <div class="mt-4" v-for="nt in docs" :key="nt.version">
                <div class="mt-4 px-5">
                    <div class="d-flex align-items-center">
                        <div class="d-flex flex-column">
                            <h2>
                                Versão {{nt.version}}
                            </h2>
                            <h5>{{nt.date}}</h5>
                        </div>
                        <v-btn outlined small icon @click="show !== nt.version ? show = nt.version : show = ''" class="mx-4">
                            <v-icon v-text="show === nt.version ? ic : icon"/>
                        </v-btn>
                    </div>
                </div>
                <ul v-if="show === nt.version" class="mt-4 px-5 mx-5" style="list-style-type: circle;">
                    <li v-for="desc in nt.desc" :key="nt.version + '-' + desc">
                        <h3 style="color: #555555 !important;">
                            {{desc}}
                        </h3>
                    </li>
                </ul>
            </div>
            <v-btn v-if="displaySeeMore"
                id="SeeMore"
                plain
                :loading="loading"
                elevation="0"
                color="#888"
                width="300"
                @click="handleLoadMore"
                class="mt-3">
                Ver mais
            </v-btn>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mdiPlus } from '@mdi/js';
import { mdiMinus } from '@mdi/js';

function translate(value) {
    let date = new moment(value).format('DD [de] MMMM [de] YYYY');
    let months = { 'January': 'Janeiro', 'February': 'Fevereiro', 'March': 'Março', 'April': 'Abril', 'May': 'Maio', 'June': 'Junho', 'July': 'Julho', 'August': 'Agosto', 'September': 'Setembro', 'October': 'Outubro', 'November': 'Novembro','December': 'Dezembro' };
    let str = date.split(' ');
    str[2] = months[str[2]];
    return str.join(' ');
}
export default {
    name: "Equipe",
    data: () => {
        return ({
            page: 0,
            ammount: 5,
            seeMore: false,
            loading: false,
            icon: mdiPlus,
            ic: mdiMinus,
            show: "",
            docs: [],
            alldocs: [{
                version: "1.0.0",
                date: translate("04/19/2022"),
                desc: ["Sistema lançado"]
            },{
                version: "1.0.1",
                date: translate("05/01/2022"),
                desc: ["Melhorias na pesquisa", "Modificações no footer"]
            },{
                version: "1.0.2",
                date: translate("05/10/2022"),
                desc: ["Correção na pesquisa por número", "Melhoria na pesquisa por frases", "Modificação da ordenação na pesquisa por termos", "Pesquisa por nome e número do ato", "Remoção do filtro 'Temas' na pesquisa avançada"]
            },{
                version: "1.0.3",
                date: translate("06/09/2022"),
                desc: ["Reformulação da página “sobre”", "Adicionada seção “novidades” na página “sobre”", "Adicionada seção “equipe” na página “sobre”", "Adicionada seção “linha do tempo” na página “sobre”", "Adicionada seção “documentos oficiais” na página “sobre”", "Adicionada seção “notas de atualização” na página “sobre”", "Melhorias de responsividade no rodapé", "Adicionado aviso na página principal", "Corrigido bug de alguns atos não estarem sendo exibidos na pesquisa avançada", "Filtro de pesquisa por ementa e texto inserido na busca por termos e frases", "A pesquisa por número na barra de pesquisa agora considera inicialmente a busca em texto e ementa", "Melhoria no texto da página “sobre”", "Adicionado parâmetros cumulativos nos filtros “Tipo” e “Ano” da pesquisa avançada"]
            },{
                version: "1.0.4",
                date: translate("06/10/2022"),
                desc: ["Corrigido bug ao listar atos atualizados"]
            },{
                version: "1.0.5",
                date: translate("06/22/2022"),
                desc: ["Painel de visualização da constituição reformulado", "Adicionado novas informações da constituição", "Adicionado arquivo original da constituição"]
            }]
        })
    },
    methods: {
        getFirstResults: function () {
            let aux = [...this.alldocs];
            this.docs = aux.splice(0, this.ammount);
        },
        handleLoadMore: function () {
            this.loading = true;
            this.page++;
            let aux = [...this.alldocs];
            let more = aux.splice(this.page*this.ammount, this.ammount);
            this.docs = this.docs.concat(more);
            this.loading = false;
        },
    },
    mounted: function () {
        this.getFirstResults();
    },
    computed: {
        displaySeeMore: function () {
            return (this.docs.length < this.alldocs.length)
        }
    }
}
</script>

<style>
@import "../About.css";

ul li {
    list-style-type: disc;
    font-size: 2rem !important;
    color: #404040;
}
</style>